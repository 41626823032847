import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import { AcademicCapIcon } from '@heroicons/react/outline'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { routes } from '../../utils/routes'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

const RegisterMsg: React.FC = () => {
    const { t } = useTranslation(['common'])
    const { data: session } = useSession()
    const router = useRouter()

    const [showMsg, setShowMsg] = useState<boolean>(false)

    if (!session?.user && router.pathname !== '/testen') {
        setTimeout(() => setShowMsg(true), 10000)
    }

    return (
        <div className="relative z-20 sm:z-30">
            <Transition
                show={showMsg}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in "
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Link href={routes.register.path}>
                    <a href={routes.register.path}>
                        <div className="fixed top-[var(--dann-footer-height-lg)] z-20 sm:z-30 mx-2 sm:mx-0 right-0 sm:right-5 my-2 transform rounded-lg bg-mariner-500 p-3 text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                            <div className="mt-3 flex space-x-3 sm:mt-0 text-left text-white mr-5">
                                <AcademicCapIcon className="h-14 w-14 sm:h-10 sm:w-10" />
                                <p className="text-sm font-thin text-white mr-5">{t('register-msg')}</p>
                            </div>
                        </div>
                        <div className="fixed h-5 w-5 bg-mariner-500 rotate-45 top-16 sm:right-10 right-7"></div>
                    </a>
                </Link>
            </Transition>
        </div>
    )
}

export default RegisterMsg
